import { apiBaseQuery } from '@cognitranlimited/itis-web';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
export var topMenuLinksApi = createApi({
    reducerPath: 'topMenuLinksApi',
    baseQuery: apiBaseQuery(),
    endpoints: function (builder) { return ({
        basketInfo: builder.query({
            query: function () { return ({
                url: "/service/basket/lines",
            }); },
            extraOptions: {
                target: 'itis'
            }
        })
    }); }
});
export var useBasketInfoQuery = topMenuLinksApi.useBasketInfoQuery;
