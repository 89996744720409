import { apiBaseQuery } from '@cognitranlimited/itis-web';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
function getVehicleApiRoot(vehicle) {
    if (vehicle === null) {
        return '';
    }
    switch (vehicle.ref) {
        case 'vin':
            return "/vehicles/".concat(vehicle.vin);
        case 'product': {
            var path = "/product-variants/".concat(vehicle.productVariantId);
            if (vehicle.year) {
                path += "/".concat(vehicle.year);
            }
            return path;
        }
        default:
            throw new Error('Invalid vehicle reference: ' + JSON.stringify(vehicle));
    }
}
export var leftMenuLinksApi = createApi({
    reducerPath: 'leftMenuLinksApi',
    baseQuery: apiBaseQuery(),
    endpoints: function (builder) { return ({
        getDocuments: builder.query({
            query: function (_a) {
                var vehicle = _a.vehicle, groupId = _a.groupId;
                return "".concat(getVehicleApiRoot(vehicle), "/documents/groups/").concat(groupId, "?filter=&pageNumber=0&pageSize=20&sortDirection=ascending&sortKey=title");
            },
            extraOptions: {
                timeout: 30000
            }
        }),
    }); }
});
export var useGetDocumentsQuery = leftMenuLinksApi.useGetDocumentsQuery;
