import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Badge, Link } from '@cognitranlimited/itis-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useBasketInfoQuery } from '../topMenuLinksApi';
import { Snackbar } from '../Snackbar/Snackbar';
import './BasketItem.scss';
export var BasketItem = function (_a) {
    var title = _a.title, href = _a.href, variant = _a.variant;
    var _b = useState(0), badgeCount = _b[0], setBadgeCount = _b[1];
    var _c = useState(""), basketNotification = _c[0], setBasketNotification = _c[1];
    var classes = classNames('BasketItem', {
        'BasketItem-mobile': variant === 'mobile'
    });
    var data = useBasketInfoQuery().data;
    useEffect(function () {
        (data === null || data === void 0 ? void 0 : data.lines) && setBadgeCount(data === null || data === void 0 ? void 0 : data.lines);
    }, [data]);
    useEffect(function () {
        var handler = function (event) {
            if (event.data.updateBasket === "true") {
                setBadgeCount(event.data.itemsInBasket);
                setBasketNotification(event.data.successMessage);
            }
        };
        window.addEventListener("message", handler);
        return function () { return window.removeEventListener('message', handler); };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Snackbar, { open: Boolean(basketNotification), message: basketNotification, onClose: function () { return setBasketNotification(""); }, variant: variant }),
        React.createElement(Link, { className: classes, href: href, title: title, ariaLabel: title },
            React.createElement(Badge, { count: badgeCount },
                React.createElement(FontAwesomeIcon, { icon: faShoppingCart, size: variant === "mobile" ? "lg" : "2x" })))));
};
